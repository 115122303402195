import React from 'react';
import {Link} from 'gatsby';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Helmet } from "react-helmet"


export default function Features () {


    return (

        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Features | Skillmeter</title>
            <meta name="description" content="Take a tour of Skillmeter features to see how it helps you hire faster and better." />
            <meta name="keywords" content="skillmeter features, skillmeter tour" />

        </Helmet>

        <Header />

        <section className="py-12 bg-gray-50 sm:py-16 lg:py-20">
      <div className="px-4 mx-auto sm:px-6 lg:px-8">
        <div className="px-8 mx-auto text-center md:px-0">
          <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl xl:text-5xl ">
          Features
          </h2>
          <p className="mt-6 text-lg font-normal text-gray-600 ">
          Everything you need to create, administer &amp; deliver secure Online Assessments
          </p>
        </div>

        {/* create tests */ }
        <div className="mt-10 max-w-xl mx-auto text-center xl:max-w-2xl">
          <h2 className="text-3xl font-bold underline leading-tight text-gray-900 sm:text-4xl">
            Create Tests
          </h2>
        </div>

        <section className="py-12 sm:py-16 lg:py-20">
          <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="grid grid-cols-1 md:items-center gap-y-8 md:grid-cols-2 md:gap-x-16">
              <div className="text-center md:text-left lg:pr-16">
                <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl ">
                  Create tests
                </h2>
                <p className="mt-4 text-lg text-gray-700 sm:mt-8 ">
                Skillmeter offers everything you need to create and assign customized assessments to specific candidates. The tests can be timed, scored and allow candidates to skip or review questions at the end of the session.
                </p>
              </div>

              <div>
                <img className="w-full max-w-md mx-auto" src="https://d2mwjjtum67wgo.cloudfront.net/Content/Public3/img/tour/createtest2.png" alt="" />
              </div>
            </div>
          </div>
        </section>

        <section className="py-12 sm:py-16 lg:py-20">
          <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="grid grid-cols-1 md:items-center gap-y-8 md:grid-cols-2 md:gap-x-16">
              <div>
                <img className="w-full max-w-sm mx-auto" src="https://d2mwjjtum67wgo.cloudfront.net/Content/Public3/img/tour/addquestion.png" alt="" />
              </div>

              <div className="text-center md:text-left lg:pr-16">
                <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl">
                Add your questions
                </h2>
                <p className="mt-4 text-lg text-gray-700 sm:mt-8">
                Adding custom questions is as simple as <b>1-2-3</b>
                </p>
                <p className="mt-4 text-lg text-gray-700 sm:mt-8 ">
                We currently offer 12 different types that you can add and mix in each of your tests:
                </p>
                <p className="mt-4 text-lg text-gray-700 sm:mt-8 ">
                <ul>
                        <li>Instructions</li>
                        <li>Multiple choice with only one correct answer</li>
                        <li>Multiple choice with two or more correct answers</li>
                        <li>True/False</li>
                        <li>Ranking</li>
                        <li>Exact match</li>
                        <li>"Fill in the blank" / Essay</li>
                        <li>Picture Choice</li>
                        <li>File Upload</li>
                        <li>Typing Speed</li>
                        <li>Video Recording</li>
                        <li>Programming</li>
                    </ul>
                </p>

              </div>
            </div>
          </div>
        </section>

        {/* administer candidates tests */ }
        <div className="mt-10 max-w-xl mx-auto text-center xl:max-w-2xl">
          <h2 className="text-3xl font-bold underline leading-tight text-gray-900 sm:text-4xl">
            Administer Candidates
          </h2>
        </div>

        <section className="py-12 sm:py-16 lg:py-20">
          <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="grid grid-cols-1 md:items-center gap-y-8 md:grid-cols-2 md:gap-x-16">
              <div className="text-center md:text-left lg:pr-16">
                <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl ">
                Manage your candidates
                </h2>
                <p className="mt-4 text-lg text-gray-700 sm:mt-8 ">
                This section shows you all your candidates: names, the tests they have taken and how they did, and displays their unique pin code. You can filter the candidates and search for specific people.
              </p>
              </div>

              <div>
                <img className="w-full max-w-md mx-auto" src="https://d2mwjjtum67wgo.cloudfront.net/Content/Public3/img/tour/candidates.png" alt="" />
              </div>
            </div>
          </div>
        </section>

        <section className="py-12 sm:py-16 lg:py-20">
          <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="grid grid-cols-1 md:items-center gap-y-8 md:grid-cols-2 md:gap-x-16">
              <div>
                <img className="w-full max-w-sm mx-auto" src="https://d2mwjjtum67wgo.cloudfront.net/Content/Public3/img/tour/testresults.png" alt="" />
              </div>

              <div className="text-center md:text-left lg:pr-16">
                <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl">
                Test result
                </h2>
                <p className="mt-4 text-lg text-gray-700 sm:mt-8">
                Immediately after a candidate finishes a test session, the test report becomes available to the recruiter and provides the overall score and details on how the candidate answered each question. The report is available on Skillmeter and can also be downloaded as a PDF file.
                </p>

              </div>
            </div>
          </div>
        </section>

        {/* test center */ }
        <div className="mt-10 max-w-xl mx-auto text-center xl:max-w-2xl">
          <h2 className="text-3xl font-bold underline leading-tight text-gray-900 sm:text-4xl">
            Test Center
          </h2>
        </div>

        <section className="py-12 sm:py-16 lg:py-20">
          <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="grid grid-cols-1 md:items-center gap-y-8 md:grid-cols-2 md:gap-x-16">
              <div className="text-center md:text-left lg:pr-16">
                <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl ">
                Test center
                </h2>
                <p className="mt-4 text-lg text-gray-700 sm:mt-8 ">
                To access the test center, the candidates will go to your unique Skillmeter sub-domain name (your_company.skillmeter.com).
              </p>
              <p className="mt-4 text-lg text-gray-700 sm:mt-8 ">
              You can personalize the test center with your company logo and with links to your company's website and Facebook, Twitter and LinkedIn pages.
            </p>

              </div>

              <div>
                <img className="w-full max-w-md mx-auto" src="https://d2mwjjtum67wgo.cloudfront.net/Content/Public3/img/tour/testcenter2.png" alt="" />
              </div>
            </div>
          </div>
        </section>


        <section className="py-12 sm:py-16 lg:py-20">
          <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="grid grid-cols-1 md:items-center gap-y-8 md:grid-cols-2 md:gap-x-16">
              <div>
                <img className="w-full max-w-sm mx-auto" src="https://d2mwjjtum67wgo.cloudfront.net/Content/Public3/img/tour/resultnotification.png" alt="" />
              </div>

              <div className="text-center md:text-left lg:pr-16">
                <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl">
                Result notifications
                </h2>
                <p className="mt-4 text-lg text-gray-700 sm:mt-8">
                You will know when a candidate finishes a test as Skillmeter will send you an email as soon as your candidate completes the test.
                </p>

              </div>
            </div>
          </div>
        </section>

      {/* And much more */}
      <div className="mt-10 max-w-xl mx-auto text-center xl:max-w-2xl">
        <h2 className="text-3xl font-bold underline leading-tight text-gray-900 sm:text-4xl">
          And much more
        </h2>
      </div>

      <section className="py-12  sm:py-16 lg:py-20">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 text-center gap-y-10 md:grid-cols-2 md:text-left">
          
          <div className="md:pr-6 lg:pr-12">
            <h3 className="mt-12 text-lg font-bold text-gray-900 ">
            Bring your team
            </h3>
            <p className="mt-5 text-base text-gray-600">
            Skilmeter was designed to work for teams. Each team member has their own account that enables them to access Skillmeter from anywhere.
          </p>
          </div>

          <div className="w-56 h-px mx-auto bg-gray-200 md:hidden"></div>

          <div className="md:pl-6 lg:pl-12">
            <h3 className="mt-12 text-lg font-bold text-gray-900 ">
            Printing works too
            </h3>
            <p className="mt-5 text-base text-gray-600 ">
            If you still need to print tests we let you you do that that also. All tests created on Skillmeter can be easily printed and used.
          </p>
          </div>

          <div className="w-56 h-px mx-auto bg-gray-200 md:hidden"></div>

          <div className="md:pr-6 lg:pr-12 ">
            <h3 className="mt-12 text-lg font-bold text-gray-900 ">
            Reporting
            </h3>
            <p className="mt-5 text-base text-gray-600 ">
            We provide basic reporting and also the possibility to export the results to analyze them in your favorite tool (like Excel).
          </p>
          </div>

          <div className="w-56 h-px mx-auto bg-gray-200 md:hidden"></div>

          <div className="md:pl-6 lg:pl-12">
            <h3 className="mt-12 text-lg font-bold text-gray-900 ">
            API
            </h3>
            <p className="mt-5 text-base text-gray-600 ">
            Build your own integrations with our REST API. <Link to={'/api'}>Learn more</Link>.

          </p>
          </div>          
        </div>
      </div>
    </section>      


      </div>
    </section>

      {/* CTA */}
      <section className="py-4 bg-blue-50 sm:py-6 lg:py-8">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="mx-auto text-4xl font-bold text-gray-900 ">
            Ready to give us a try?  The first 14 days are free.
          </h2>


            <Link to={'/pricing'} className="mt-4 inline-flex items-center justify-center px-6 py-3 text-base font-bold bg-smcolor text-white rounded-xl focus:outline-none hover:bg-smcolor-dark  hover:text-white " role="button">
              See Plans &amp; Pricing
            </Link>

        </div>
      </div>
    </section>




        <Footer />
        </>
    );
}